<template>
  <div v-if="thisShow">
    <div class="wrap">
      <Title name="店铺信息"
             :operate="true">
        <span v-if="isAdmin">
          <div class="edit-btn"
               @click="editClick"
               v-if="pageStete === 'success'">编辑</div>
        </span>

      </Title>

      <el-alert :title="errorInfo"
                type="error"
                show-icon
                v-if="pageStete === 'error'">
      </el-alert>

      <div class="content">
        <el-form :model="ruleForm"
                 :rules="rules"
                 ref="ruleForm"
                 label-width="150px"
                 v-if="pageStete === 'edit' || pageStete === 'error'"
                 key="ruleForm">
          <el-row :gutter="30">
            <el-col :span="11">
              <el-form-item label="店铺LOGO："
                            prop="image">
                <el-upload class="chen-image-uploader"
                           :action="uploadPath"
                           :show-file-list="false"
                           :on-success="handleSuccess"
                           :before-upload="beforeUpload">
                  <img v-if="ruleForm.image"
                       :src="ruleForm.image"
                       class="upload-image">
                  <i v-else
                     class="el-icon-plus image-uploader-icon"></i>
                </el-upload>
              </el-form-item>
            </el-col>
            <el-col :span="11">
              <el-form-item label="店铺名称："
                            prop="name">
                <el-input v-model="ruleForm.name"
                          placeholder="请输入店铺名称"
                          size="mini"></el-input>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row :gutter="30">
            <el-col :span="11">
              <el-form-item label="管理用户："
                            prop="users">
                <el-select v-model="ruleForm.users"
                           placeholder="请选择"
                           key="users"
                           size="mini">
                  <el-option v-for="item in usersList"
                             :key="item.value"
                             :label="item.label"
                             :value="item.value">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="11">
              <el-form-item label="提货地址："
                            prop="address">
                <el-input v-model="ruleForm.address"
                          placeholder="请输入提货地址"
                          size="mini"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="30">
            <el-col :span="11">
              <el-form-item label="营业时间："
                            prop="time">
                <el-time-picker
                        is-range
                        v-model="ruleForm.time"
                        size="mini"
                        value-format="HH:mm:ss"
                        format="HH:mm:ss"
                        range-separator="至"
                        start-placeholder="开始时间"
                        end-placeholder="结束时间"
                        placeholder="选择时间范围">
                </el-time-picker>
              </el-form-item>
            </el-col>
            <el-col :span="11">
              <el-form-item label="主营类目:"
                            prop="explain">
                <el-cascader v-model="ruleForm.explain"
                             :options="categoryList"
                             expand-trigger="hover"
                             @change="handleCategoryChange"
                             size="mini" />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="30">
            <el-col :span="11">
              <el-form-item label="非工作营业时间："
                            prop="noWorkTime">
                <el-time-picker
                        is-range
                        v-model="ruleForm.noWorkTime"
                        size="mini"
                        value-format="HH:mm:ss"
                        format="HH:mm:ss"
                        range-separator="至"
                        start-placeholder="开始时间"
                        end-placeholder="结束时间"
                        placeholder="选择时间范围">
                </el-time-picker>
              </el-form-item>
            </el-col>
          </el-row>
          <!--        <el-form-item label="主营类目：" prop="explain">-->
          <!--          <el-input v-model="ruleForm.explain" :rows="6" type="textarea" style="width: 90%"></el-input>-->
          <!--        </el-form-item>-->
          <!--<el-row :gutter="30">-->

          <!--</el-row>-->


          <el-form-item label="店铺简介："
                        prop="detail">
            <tinymce-editor :value="ruleForm.detail"
                            @input="ruleForm.detail = $event"
                            style="width: 90%"></tinymce-editor>
          </el-form-item>

          <el-form-item>
            <!--          <el-button class="btn" plain @click="submitForm('ruleForm', 'preserve')">保存</el-button>-->
            <el-button class="btn"
                       type="primary"
                       @click="submitForm('ruleForm', 'submit')">确认并提交</el-button>
          </el-form-item>
        </el-form>

        <el-form :model="ruleForm"
                 ref="ruleForm"
                 label-width="150px"
                 v-if="pageStete === 'success'"
                 key="ruleForm2">
          <el-row :gutter="30">
            <el-col :span="11">
              <el-form-item label="店铺LOGO：">
                <div class="chen-image-uploader">
                  <img v-if="ruleForm.image"
                       :src="ruleForm.image"
                       class="upload-image">
                </div>
              </el-form-item>
            </el-col>
            <el-col :span="11">
              <el-form-item label="店铺名称：">
                {{ruleForm.name}}
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="30">
            <el-col :span="11">
              <el-form-item label="管理用户：">
                <p>{{ruleForm.userName}}</p>
              </el-form-item>
            </el-col>
            <el-col :span="11">
              <el-form-item label="提货地址：">
                <p>{{ruleForm.address}}</p>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="30">
            <el-col :span="11">
              <el-form-item label="工作日营业时间：">
                <p>{{ruleForm.startTime}} 至 {{ruleForm.endTime}}</p>
              </el-form-item>
            </el-col>
            <el-col :span="11">
              <el-form-item label="非工作日营业时间：">
                <p v-if="ruleForm.noWorkStartTime!=undefined">{{ruleForm.noWorkStartTime}} 至 {{ruleForm.noWorkEndTime}}</p>
                <p v-else>不营业</p>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="30">
            <el-col :span="11">
              <el-form-item label="主营类目：">
                <p style="width: 90%">{{ruleForm.explain2}}</p>
              </el-form-item>
            </el-col>
          </el-row>

          <el-form-item label="店铺简介：">
            <div style="width: 90%"
                 v-html="ruleForm.detail"></div>
          </el-form-item>
        </el-form>

        <div class="submit-state"
             v-if="pageStete === 'pending'">
          <div class="state-icon">
            <i class="el-icon-success"></i>
          </div>
          <p class="state-msg">审核中</p>
          <p class="state-desc">您的店铺信息已提交成功，等待平台审核，请及时查询</p>
        </div>
      </div>
    </div>
  </div>
  <div v-else>
    <empty text="暂无店铺信息"></empty>
  </div>
</template>

<script>
import empty from '../../component/empty';
import Title from '../../component/Title';
import tinymceEditor from '@/components/tinymceEditor.vue';
import { categoryAll, selectSubInfo, applyBrand, selectDtsBrandByCommpany } from "../../api";
import api from '@/api/config';
export default {
  components: {
    'Title': Title,
    'empty': empty,
    'tinymce-editor': tinymceEditor
  },
  data () {
    return {
      uploadPath: api.uploadPath,
      thisShow: true,
      errorInfo: '',
      isAdmin: true,
      categoryIds: [],
      categoryList: [],
      goods: { picUrl: '', gallery: [] },
      pageStete: 'edit', // edit: 编辑; pending: 审核中; success: 审核成功; error: 审核失败。
      ruleForm: {
        id: '0',
        image: '',
        name: '',
        users: '',
        userName: '',
        address: '',
        explain: '',
        explain2: '',
        detail: '',
        imageUrl: "",
        time:[new Date(2000,1,1,9,0,0),new Date(2000,1,1,18,0,0)],
        startTime:new Date(2000,1,1,9,0,0),
        endTime:new Date(2000,1,1,18,0,0),
        noWorkTime:[new Date(2000,1,1,0,0,0),new Date(2000,1,1,0,0,0)],
        noWorkStartTime:new Date(2000,1,1,0,0,0),
        noWorkEndTime:new Date(2000,1,1,0,0,0),
      },
      rules: {
        image: [
          { required: true, message: '请上传LOGO', trigger: ['blur', 'change'] }
        ],
        name: [
          { required: true, message: '请输入店铺名称', trigger: 'blur' }
        ],
        users: [
          { required: true, message: '请选择管理用户', trigger: 'change' }
        ],
        address: [
          { required: true, message: '请输入提货地址', trigger: 'blur' }
        ],
        explain: [
          { required: true, message: '请输入主营类目', trigger: 'blur' }
        ],
        detail: [
          { required: true, message: '请输入店铺简介', trigger: 'blur' }
        ],
        time: [
          { required: true, message: '请选择工作时间营业时间', trigger: 'blur' }
        ],
        // noWorkTime: [
        //   { required: true, message: '请选择非工作时间营业时间', trigger: 'blur' }
        // ]
      },
      usersList: []
    }
  },
  created () {
    var _this = this;

    selectDtsBrandByCommpany().then(
      res => {
        if (res.errno == 0) {
          if (res.data.byCommpany.status == "A") {
            _this.pageStete = 'pending'; //审核中
          } else if (res.data.byCommpany.status == "P") {
            _this.pageStete = 'success'; //审核通过
            // console.log(res.data.byCommpany)
            _this.ruleForm.name = res.data.byCommpany.name
            _this.ruleForm.users = res.data.byCommpany.adminId
            _this.ruleForm.address = res.data.byCommpany.address
            _this.ruleForm.detail = res.data.byCommpany.desc
            _this.ruleForm.explain = res.data.byCommpany.defaultCategoryId
            _this.ruleForm.explain2 = res.data.dtsCategoryName
            _this.ruleForm.image = res.data.byCommpany.picUrl
            _this.ruleForm.userName = res.data.users
            _this.ruleForm.id = res.data.byCommpany.id
            _this.ruleForm.imageUrl = res.data.byCommpany.picUrl
            //营业时间
            _this.setResTime(res)

            // console.log("id" + res.data.byCommpany.id)
            var parentId = localStorage.getItem("parentId");
            var userId = localStorage.getItem("userId");
            if (parentId != 0) {//子账号
              if (res.data.byCommpany.adminId != userId) {
                _this.isAdmin = false
                // alert("不是管理员")
              } else {
                _this.isAdmin = true
                // alert("是管理员")
              }
            } else {
              _this.isAdmin = true
            }

          } else if (res.data.byCommpany.status == "F") {
            _this.pageStete = 'error'; //审核失败
            _this.ruleForm.name = res.data.byCommpany.name
            _this.ruleForm.users = res.data.byCommpany.adminId
            _this.ruleForm.address = res.data.byCommpany.address
            _this.ruleForm.detail = res.data.byCommpany.desc
            _this.ruleForm.explain = res.data.byCommpany.defaultCategoryId
            _this.ruleForm.explain2 = res.data.dtsCategoryName
            _this.ruleForm.image = res.data.byCommpany.picUrl
            _this.ruleForm.userName = res.data.users
            _this.ruleForm.id = res.data.byCommpany.id
            _this.ruleForm.imageUrl = res.data.byCommpany.picUrl
            _this.errorInfo = res.data.byCommpany.statusRemark
            //营业时间
            _this.setResTime(res)
          }
        } else if (res.errno == -2) {//没有店铺信息
          var parentId = localStorage.getItem("parentId");
          if (parentId != 0) {//子账户
            _this.thisShow = false
          }
        }
      }
    )
    selectSubInfo().then(
      res => {
        _this.usersList = undefined;
        _this.usersList = new Array();
        var userId = Number(localStorage.getItem("userId"));
        var users = localStorage.getItem("users");
        _this.usersList.push({
          value: userId,
          label: users
        });
        for (var v in res.data) {
          _this.usersList.push({
            value: res.data[v].id,
            label: res.data[v].users
          })
          // console.log(res.data[v])
        }
        var parentId = localStorage.getItem("parentId");
        if (parentId != 0) {
          _this.ruleForm.users = userId
        }
      }
    )

    this.getAllCategory();

  },
  methods: {
    handleCategoryChange (value) {
      this.goods.categoryId = value[value.length - 1]
      // console.log("id" + this.ruleForm.id)
    },
    getAllCategory () {
      categoryAll().then(res => {
        this.categoryList = res.data.map(parent => {
          return {
            value: parent.id,
            label: parent.name,
            children: parent.child.map(child => {
              return {
                value: child.id,
                label: child.name
              }
            })
          }
        });
      });
    },
    // 图片上传之前
    beforeUpload (file) {
      const isJPG = file.type === 'image/jpeg' || file.type === 'image/png';
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isJPG) {
        this.$message.error('上传头像图片只能是 JPG/PNG 格式!');
      }
      if (!isLt2M) {
        this.$message.error('上传头像图片大小不能超过 2MB!');
      }
      return isJPG && isLt2M;
    },
    // 图片上传成功
    handleSuccess (res, file) {
      this.ruleForm.image = URL.createObjectURL(file.raw);
      this.ruleForm.imageUrl = res.data.url

    },
    // 编辑
    editClick () {
      this.pageStete = 'edit'; //编辑
    },
    setTime(){
      if(this.ruleForm.time!=null && this.ruleForm.time.length==2){
        this.ruleForm.startTime=this.ruleForm.time[0]
        this.ruleForm.endTime=this.ruleForm.time[1]
      }else{
        this.ruleForm.startTime=undefined
        this.ruleForm.endTime=undefined
      }
      console.log(this.ruleForm.noWorkTime)
      if(this.ruleForm.noWorkTime!=null && this.ruleForm.noWorkTime.length==2){
        this.ruleForm.noWorkStartTime=this.ruleForm.noWorkTime[0]
        this.ruleForm.noWorkEndTime=this.ruleForm.noWorkTime[1]
      }else{
        this.ruleForm.noWorkStartTime=undefined
        this.ruleForm.noWorkEndTime=undefined
      }
    },
    setResTime(res){
      console.log(res.data.byCommpany)
      this.ruleForm.startTime = res.data.byCommpany.startTime
      this.ruleForm.endTime = res.data.byCommpany.endTime
      this.ruleForm.time=[res.data.byCommpany.startTime,res.data.byCommpany.endTime]
      debugger
      if(res.data.byCommpany.noWorkStartTime=="00:00:00" && res.data.byCommpany.noWorkEndTime=="00:00:00"){
        this.ruleForm.noWorkStartTime=undefined
        this.ruleForm.noWorkEndTime=undefined
      }else{
        this.ruleForm.noWorkStartTime = res.data.byCommpany.noWorkStartTime
        this.ruleForm.noWorkEndTime = res.data.byCommpany.noWorkEndTime
        this.ruleForm.noWorkTime=[res.data.byCommpany.noWorkStartTime,res.data.byCommpany.noWorkEndTime]
      }
    },
    // 提交
    submitForm (formName, type) {
      var _this = this;
      _this.$refs[formName].validate((valid) => {
        if (valid) {
          console.log("------")
          console.log(_this.ruleForm)
          _this.setTime();
          if (type === 'preserve') { //保存
            var defaultCategoryId = _this.ruleForm.explain.length
            console.log(_this.ruleForm.explain[defaultCategoryId - 1])
            applyBrand({
              defaultCategoryId: _this.ruleForm.explain,
              name: _this.ruleForm.name,
              picUrl: _this.ruleForm.imageUrl,
              adminId: _this.ruleForm.users,
              address: _this.ruleForm.address,
              desc: _this.ruleForm.detail,
              id: _this.ruleForm.id,
              startTime:_this.ruleForm.startTime,
              endTime:_this.ruleForm.endTime,
              noWorkStartTime:_this.ruleForm.noWorkStartTime,
              noWorkEndTime:_this.ruleForm.noWorkEndTime,
            }).then(
              res => {
                if (res.errno == 0) {
                  _this.$notify({
                    title: '保存成功',
                    message: '您的店铺信息已保存成功!',
                    type: 'success'
                  });
                }
              }
            )

          } else { //提交
            var defaultCategoryId = _this.ruleForm.explain.length
            console.log(_this.ruleForm.explain[defaultCategoryId - 1])
            applyBrand({
              defaultCategoryId: _this.ruleForm.explain,
              name: _this.ruleForm.name,
              picUrl: _this.ruleForm.imageUrl,
              adminId: _this.ruleForm.users,
              address: _this.ruleForm.address,
              desc: _this.ruleForm.detail,
              id: _this.ruleForm.id,
              startTime:_this.ruleForm.startTime,
              endTime:_this.ruleForm.endTime,
              noWorkStartTime:_this.ruleForm.noWorkStartTime,
              noWorkEndTime:_this.ruleForm.noWorkEndTime,
            }).then(
              res => {
                if (res.errno == 0) {
                  _this.$notify({
                    title: '提交成功',
                    message: '您的店铺信息已提交成功!',
                    type: 'success'
                  });

                  _this.pageStete = 'pending'; //审核中
                }
              }
            )

          }
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    }
  }
}
</script>

<style lang="less" scoped>
.wrap {
  background: #fff;

  .el-select,
  .el-input {
    width: 100%;
  }
  .content {
    position: relative;
    padding: 20px;
    box-sizing: border-box;

    .chen-image-uploader {
      .image-uploader-icon {
        font-size: 28px;
        color: #8c939d;
        width: 90px;
        height: 90px;
        line-height: 90px;
        text-align: center;
      }
      .upload-image {
        width: 90px;
        height: 90px;
        display: block;
      }
    }

    .submit-state {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 300px;
      background: #fff;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .state-icon {
        font-size: 60px;
        margin-top: -100px;

        .el-icon-success {
          color: #55c176;
        }
      }
      .state-msg {
        font-weight: bold;
        font-size: 18px;
        margin-top: 0px;
      }
      .state-desc {
        font-weight: bold;
        font-size: 15px;
        margin-top: 18px;
        margin-bottom: 30px;
      }
    }

    .btn {
      width: 120px;
    }
  }
  .edit-btn {
    cursor: pointer;
    color: #4db1cd;
  }
}
</style>
<style lang="less">
.chen-image-uploader {
  .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    position: relative;
    overflow: hidden;

    &:hover {
      border-color: #409eff;
    }
  }
}
</style>
