<template>
    <div class="empty-container">
        <img class="empty-image" v-if="imgUrl" src="" alt="">
        <div class="empty-text">{{text}}</div>
    </div>
</template>

<script>
export default {
    props: {
        imgUrl: {
            type: String
        },
        text: {
            type: String
        }
    }
}
</script>

<style lang="less" scope>
.empty-container {
    font-size: 12px;
    color: #999999;
    text-align: center;

    .empty-image {

    }
    .empty-text {
        margin-top: 10px;
    }
}
</style>